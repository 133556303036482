import { NoIngresadoGuard } from './no-ingresado.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IngresadoGuard } from './ingresado.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
     ,canActivate:[IngresadoGuard]
  },
  {
    path: 'registro',
    loadChildren: () => import('./registro/registro.module').then( m => m.RegistroPageModule)
    //, canActivate:[NoIngresadoGuard]
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
    ,canActivate:[NoIngresadoGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
    ,canActivate:[NoIngresadoGuard]
  },
  {
    path: 'noticias',
    loadChildren: () => import('./pages/noticias/noticias.module').then( m => m.NoticiasPageModule)
    ,canActivate:[NoIngresadoGuard]
  },
  {
    path: 'adquiere',
    loadChildren: () => import('./pages/adquiere/adquiere.module').then( m => m.AdquierePageModule)
    ,canActivate:[NoIngresadoGuard]
  },
  {
    path: 'compras',
    loadChildren: () => import('./pages/compras/compras.module').then( m => m.ComprasPageModule)
    ,canActivate:[NoIngresadoGuard]
  },
  {
    path: 'cardio-dance',
    loadChildren: () => import('./pages/entrenamientos/cardio-dance/cardio-dance.module').then( m => m.CardioDancePageModule)
    ,canActivate:[NoIngresadoGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
    ,canActivate:[IngresadoGuard]
  },
  {
    path: 'movimientos/:id',
    loadChildren: () => import('./movimientos/movimientos.module').then( m => m.MovimientosPageModule)
    ,canActivate:[NoIngresadoGuard]
  },
  {
    path: 'citas/:id', //   /:id
    loadChildren: () => import('./citas/citas.module').then( m => m.CitasPageModule)
    ,canActivate:[NoIngresadoGuard]
  },
  {
    path: 'misdatos/:id',
    loadChildren: () => import('./misdatos/misdatos.module').then( m => m.MisdatosPageModule)
    ,canActivate:[NoIngresadoGuard]
  },
  {
    path: 'credencial',
    loadChildren: () => import('./pages/credencial/credencial.module').then( m => m.CredencialPageModule)
    ,canActivate:[NoIngresadoGuard]
  },
  {
    path: 'lista-retos',
    loadChildren: () => import('./pages/lista-retos/lista-retos.module').then( m => m.ListaRetosPageModule)
    ,canActivate:[NoIngresadoGuard]
  },
  {
    path: 'pass',
    loadChildren: () => import('./pages/pass/pass.module').then( m => m.PassPageModule)
  },
  {
    path: 'recovery_pass',
    loadChildren: () => import('./pages/recovery_pass/recovery_pass.module').then( m => m.Recovery_passPageModule)
  },
  {
    path: 'carro-modal',
    loadChildren: () => import('./pages/carro-modal/carro-modal.module').then( m => m.CarroModalPageModule)
  },
  {
    path: 'estacionamiento',
    loadChildren: () => import('./pages/estacionamiento/estacionamiento.module').then( m => m.EstacionamientoPageModule)
  },
  {
    path: 'pesaje',
    loadChildren: () => import('./pages/pesaje/pesaje.module').then( m => m.PesajePageModule)
  },
  {
    path: 'rutinas',
    loadChildren: () => import('./pages/rutinas/rutinas.module').then( m => m.RutinasPageModule)
  },
  {
    path: 'rutinas_chat',
    loadChildren: () => import('./pages/rutinas_chat/rutinas_chat.module').then( m => m.Rutinas_chatPageModule)
  },
  {
    path: 'rutinas_general',
    loadChildren: () => import('./pages/rutinas_general/rutinas_general.module').then( m => m.Rutinas_generalPageModule)
  },
  {
    path: 'facturas/:id',
    loadChildren: () => import('./pages/facturas/facturas.module').then( m => m.FacturasPageModule)
  },
  {
    path: 'bandas',
    loadChildren: () => import('./pages/bandas/bandas.module').then( m => m.BandasPageModule)
  },
  {
    path: 'pago_online',
    loadChildren: () => import('./pages/pago_online/pago_online.module').then( m => m.Pago_onlineModule)
  },
  {
    path: 'smartwatch',
    loadChildren: () => import('./pages/smartwatch/smartwatch.module').then( m => m.SmartwatchPageModule)
  },
  {
    path: 'ranking',
    loadChildren: () => import('./pages/ranking/ranking.module').then( m => m.RankingPageModule)
  },
  {
    path: 'domiciliar',
    loadChildren: () => import('./pages/domiciliar/domiciliar.module').then( m => m.DomiciliarPageModule)
  },
  {
    path: 'dieta',
    loadChildren: () => import('./pages/dieta/dieta.module').then( m => m.DietaPageModule)
  },
  {
    path: 'chat-servicio',
    loadChildren: () => import('./pages/chat-servicio/chat-servicio.module').then( m => m.ChatServicioPageModule)
  },
  {
    path: 'encuesta-servicio',
    loadChildren: () => import('./encuesta-servicio/encuesta-servicio.module').then( m => m.EncuestaServicioPageModule)
  },
  {
    path: 'carrito',
    loadChildren: () => import('./carrito/carrito.module').then( m => m.CarritoPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
