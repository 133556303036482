import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Pedido } from 'src/app/models/pedido';
import { PagosPage } from 'src/app/pagos/pagos.page';


@Component({
  selector: 'app-open-pay',
  templateUrl: './open-pay.component.html',
  styleUrls: ['./open-pay.component.scss'],
})
export class OpenPayComponent implements OnInit {
  datos: any;
  totalP: number;
  urlCodigo: string;
  referencia: string;
  idCliente = PagosPage.idcliente;

  constructor(private modalController: ModalController, private loadingController: LoadingController, private router: Router,
    private alertCtrl: AlertController) {
    // console.log('mi referencia ',this.referencia);
   }

  ngOnInit() {
    this.obtenerReferencia();
  }
  async obtenerReferencia(){
    const loading = await this.loadingController.create({
      message: 'Cargando...',
    });
    loading.present();
    axios.post(`${environment.global + 'openpay/generar-referencia/' + this.idCliente +'/2'}`)
      .then((respv) => {
        console.log(respv);
        this.datos = respv.data;
        this.totalP = this.datos.monto;
        this.urlCodigo = this.datos.urlPago;
        const arreglo = this.urlCodigo.split('/');
        this.referencia = arreglo[4];
        console.log('La cantidad a pagar es:', this.totalP, ' y la liga es: ',this.urlCodigo);
        loading.dismiss();
      })
      .catch((resp) => {
        console.log('HAY UN ERROR', resp);
        loading.dismiss();
      });
  }
  async mostrarAlertaError(){
    const alert = await this.crearAlert('¡Hubo un error, inténtelo más tarde!','');
    alert.present().then(async () => {
      this.dismiss();
      setTimeout(()=>{
        this.router.navigateByUrl('/menu/pagos',{replaceUrl:true});
      }, 1000);
    });
  }
  async crearAlert(header,mensaje){
    const alert = await this.alertCtrl.create({
      header: header,
      message: mensaje,
      buttons: ['OK']
    });
    return alert;
  }
  dismiss() {
    this.modalController.dismiss();
  }
  terminosCondiciones(): void {
    if(environment.club === 'alpha'){
      window.open('https://clubalpha.com.mx/index.php/terminos-y-condiciones');
    }
    if(environment.club === 'cimera'){
      window.open('https://cimera.com.mx/terminos-y-condiciones/');
    }
    if(environment.club === 'sports'){
      window.open('https://sportsplaza.mx/terms-conditions/');
    }

  }
  avisoPrivacidad(): void {
    if(environment.club === 'alpha'){
      window.open('https://clubalpha.com.mx/index.php/aviso-de-privacidad');
    }
    if(environment.club === 'cimera'){
      window.open('https://cimera.com.mx/privacy-and-terms-2/');
    }
    if(environment.club === 'sports'){
      window.open('https://sportsplaza.mx/privacy-policy/');
    }
  }
}
