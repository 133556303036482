import { RankingModel } from './../../models/ranking.model';
/* eslint-disable max-len */
import {Injectable} from '@angular/core';



@Injectable({providedIn: 'root'})
export class RankingService {

  ranking=[];
  rank1: RankingModel[]=[ {
    posicion: 1,
    avatar: 'assets/images/avatar/7.jpg',
    nombre: 'María Elena',
    total: 15642,
  },
  {
    posicion: 2,
    avatar: 'assets/images/avatar/8.jpg',
    nombre: 'Fernanda',
    total: 14893,
  },
  {
    posicion: 3,
    avatar: 'assets/images/avatar/10.jpg',
    nombre: 'Vanessa',
    total: 14750,
  },
  {
    posicion: 4,
    avatar: 'assets/images/avatar/11.jpg',
    nombre: 'Erika',
    total: 13468,
  },
  {
    posicion: 5,
    avatar: 'assets/images/avatar/12.jpg',
    nombre: 'Alina',
    total: 13024,
  },
  {
    posicion: 6,
    avatar: 'assets/images/avatar/13.jpg',
    nombre: 'Gabriela',
    total: 10547,
  },
  {
    posicion: 7,
    avatar: 'assets/images/avatar/14.jpg',
    nombre: 'Sofía',
    total: 10236,
  },
  {
    posicion: 8,
    avatar: 'assets/images/avatar/15.jpg',
    nombre: 'Teresa',
    total: 19058,
  },
 
 ];

  rank2: RankingModel[]=[ {
    posicion: 1,
    avatar: 'assets/images/avatar/7.jpg',
    nombre: 'María Elena',
    total: 15642,
  },
  {
    posicion: 2,
    avatar: 'assets/images/avatar/8.jpg',
    nombre: 'Fernanda',
    total: 14893,
  },
  {
    posicion: 3,
    avatar: 'assets/images/avatar/10.jpg',
    nombre: 'Vanessa',
    total: 14750,
  },
  {
    posicion: 4,
    avatar: 'assets/images/avatar/11.jpg',
    nombre: 'Erika',
    total: 13468,
  },
  {
    posicion: 5,
    avatar: 'assets/images/avatar/12.jpg',
    nombre: 'Alina',
    total: 13024,
  },
  {
    posicion: 6,
    avatar: 'assets/images/avatar/13.jpg',
    nombre: 'Gabriela',
    total: 10547,
  },
  {
    posicion: 7,
    avatar: 'assets/images/avatar/14.jpg',
    nombre: 'Sofía',
    total: 10236,
  },
  {
    posicion: 8,
    avatar: 'assets/images/avatar/15.jpg',
    nombre: 'Teresa',
    total: 9058,
  },
  {
    posicion: 9,
    avatar: 'assets/images/avatar/16.jpg',
    nombre: 'Carolina',
    total: 8956,
  },
  {
    posicion: 10,
    avatar: 'assets/images/avatar/17.jpg',
    nombre: 'Sarahi',
    total: 8254,
  }];
  rank3: RankingModel[]=[ {
    posicion: 1,
    avatar: 'assets/images/avatar/7.jpg',
    nombre: 'María Elena',
    total: 15642,
  },
  {
    posicion: 2,
    avatar: 'assets/images/avatar/8.jpg',
    nombre: 'Fernanda',
    total: 14893,
  },
  {
    posicion: 3,
    avatar: 'assets/images/avatar/10.jpg',
    nombre: 'Vanessa',
    total: 14750,
  },
 ];
  rank4: RankingModel[]=[ {
    posicion: 1,
    avatar: 'assets/images/avatar/7.jpg',
    nombre: 'María Elena',
    total: 15642,
  },
  {
    posicion: 2,
    avatar: 'assets/images/avatar/8.jpg',
    nombre: 'Fernanda',
    total: 14893,
  },
  {
    posicion: 3,
    avatar: 'assets/images/avatar/10.jpg',
    nombre: 'Vanessa',
    total: 14750,
  },
  {
    posicion: 4,
    avatar: 'assets/images/avatar/11.jpg',
    nombre: 'Erika',
    total: 13468,
  },
  {
    posicion: 5,
    avatar: 'assets/images/avatar/12.jpg',
    nombre: 'Alina',
    total: 13024,
  },
  {
    posicion: 6,
    avatar: 'assets/images/avatar/13.jpg',
    nombre: 'Gabriela',
    total: 10547,
  },
  ];



  constructor()
  {

  }

  rankins(id)
  {
     if(id == 0 )
     {
        this.ranking= this.rank1
     }
     else if(id == 1 )
     {
      this.ranking= this.rank2
     }
     else if(id == 2 )
     {
      this.ranking= this.rank3
     }
     else if(id == 3 )
     {
      this.ranking= this.rank4
     }
  }
  obtenerranking(){
    return this.ranking.sort((a, b) => {
      if(a.total == b.total) {
        return 0; 
      }
      if(a.total > b.total) {
        return -1;
      }
      return 1;
    });
  }

  inscribir(id,list){
    let added = false;
    if(id == 0 )
    {
      for (let p of this.rank1) {
        if (p.nombre === list.nombre) {
          added = true;
          console.log("ya te has inscrito")
          break;
        }
      }
      if (!added) {
        
        this.rank1.push(list);
      }
       
    }
    else if(id == 1 )
    {
      for (let p of this.rank2) {
        if (p.nombre === list.nombre) {
          added = true;
          console.log("ya te has inscrito")
          break;
        }
      }
      if (!added) {
        
        this.rank2.push(list);
      }
    }
    else if(id == 2 )
    {
      for (let p of this.rank3) {
        if (p.nombre === list.nombre) {
          added = true;
          console.log("ya te has inscrito")
          break;
        }
      }
      if (!added) {
        
        this.rank3.push(list);
      }
    }
    else if(id == 3 )
    {
      for (let p of this.rank4) {
        if (p.nombre === list.nombre) {
          added = true;
          console.log("ya te has inscrito")
          break;
        }
      }
      if (!added) {
        
        this.rank4.push(list);
      }
      
    }

  
   
  }
  


    getList(): Array<RankingModel> {
        return [
            {
              posicion: 1,
              avatar: 'assets/images/avatar/7.jpg',
              nombre: 'María Elena',
              total: 15642,
            },
            {
              posicion: 2,
              avatar: 'assets/images/avatar/8.jpg',
              nombre: 'Fernanda',
              total: 14893,
            },
            {
              posicion: 3,
              avatar: 'assets/images/avatar/10.jpg',
              nombre: 'Vanessa',
              total: 14750,
            },
            {
              posicion: 4,
              avatar: 'assets/images/avatar/11.jpg',
              nombre: 'Erika',
              total: 13468,
            },
            {
              posicion: 5,
              avatar: 'assets/images/avatar/12.jpg',
              nombre: 'Alina',
              total: 13024,
            },
            {
              posicion: 6,
              avatar: 'assets/images/avatar/13.jpg',
              nombre: 'Gabriela',
              total: 10547,
            },
            {
              posicion: 7,
              avatar: 'assets/images/avatar/14.jpg',
              nombre: 'Sofía',
              total: 10236,
            },
            {
              posicion: 8,
              avatar: 'assets/images/avatar/15.jpg',
              nombre: 'Teresa',
              total: 9058,
            },
            {
              posicion: 9,
              avatar: 'assets/images/avatar/16.jpg',
              nombre: 'Carolina',
              total: 8956,
            },
            {
              posicion: 10,
              avatar: 'assets/images/avatar/17.jpg',
              nombre: 'Sarahi',
              total: 8254,
            }
        ];
    }
}
