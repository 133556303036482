import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CarritoService {
  model: any = null;
  private _cart = new BehaviorSubject<any>(null);

  get cart(){
    return this._cart.asObservable();
  }

  constructor() {
    this.getCart();
  }

//Quita un producto al carrito
  substractQuantity(item: any){
    if(this.model){
      const indexARemover = this.model.items.findIndex((datos: any) => (datos.product_id === item.product_id));
      if(indexARemover >= 0){
        if(this.model.items[indexARemover]?.quantity > 1){
          this.model.items[indexARemover].quantity -= 1;
          this.model.items[indexARemover].precio_unitario = this.costoSegunCantidad(this.model.items[indexARemover].quantity, this.model.items[indexARemover].catalogo_precios)
        }else if(this.model.items[indexARemover]?.quantity === 1){
          const allItems = this.model.items;
          const items = allItems.filter((_: any,index: number) => index !== indexARemover);
          this.model.items = items;
        }
      }
      return this.calcularPrecio();
    }
  }


//Quita productos al carrito
deleteProduct(item: any){
  if(this.model){
    const indexARemover = this.model.items.findIndex((datos: any) => (datos.product_id == item.product_id))

    if(indexARemover >= 0){
        const allItems = this.model.items;
        const items = allItems.filter((_: any,index: number) => index !== indexARemover);
        this.model.items = items;
    }
    return this.calcularPrecio();
  }
}
//Agrega productos al carrito
  addQuantity(item: any){
    if(this.model){
      const index = this.model.items.findIndex((datos)=>(datos.product_id === item.product_id));
      if(index >= 0){
        this.model.items[index].quantity += 1;
        this.model.items[index].precio_unitario =  this.costoSegunCantidad(this.model.items[index].quantity, this.model.items[index].catalogo_precios)
      }else{
        const items = [{...item,quantity:1,precio_unitario: this.costoSegunCantidad(1, item.catalogo_precios)}];
        this.model.items = items.concat(this.model.items);
      }
    }else{
      this.model = {items:[{...item, quantity:1,precio_unitario: this.costoSegunCantidad(1, item.catalogo_precios)}]};
    }
    return this.calcularPrecio();
  }
// Calcula el precio
  private calcularPrecio(){

    const items = this.model.items.filter((item: any) => item.quantity > 0);
    //Verifica si el carro esta vacío
    if (items.length <= 0){
      this.limpiarCarrito();
      return;
    }

    let itemsInCart = 0;
    let totalProducts = 0;

    for(const item of items){
      itemsInCart += item.quantity;
      totalProducts += item.precio_unitario * item.quantity;
    }

    const totalPrice = totalProducts;

    this.model = {
      ...this.model,
      itemsInCart,
      totalProducts,
      totalPrice,
    };

    this._cart.next(this.model);

    return this.model;
  }

  costoSegunCantidad(cantidad, precios){
    let costo = 0;

    let preciosOrdenados = [...precios]
    preciosOrdenados = preciosOrdenados.sort((a, b) => b.cantidad - a.cantidad)
    for(let precio of preciosOrdenados){
      if (cantidad >= precio.min_quantity){
        costo = precio.fixed_price
        break
      }
    }
    return costo;
  }

  limpiarCarrito(){
    this._cart.next(null);
    this.model = null;
  }

  async getCart(){
    this._cart.next(this.model)
    return this.model;
  }
}
