import { CheckdoService } from './../../servicios/checkdo.service';
import { Component, Input, OnInit } from '@angular/core';
declare let require: any;
// eslint-disable-next-line @typescript-eslint/naming-convention
const CryptoJS = require('crypto-js'); //importa la biblioteca necesaria para que pueda crifrar //se importa la libreria al proyecto

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.scss'],
})
export class ModalImageComponent implements OnInit {
  @Input() login: string;

  constructor(public check: CheckdoService) { }

  ngOnInit() {
  }

  decryptByDES() {
    const keyHex = CryptoJS.enc.Utf8.parse(this.login);

    // direct decrypt ciphertext
    const decrypted = CryptoJS.DES.decrypt({
        ciphertext: CryptoJS.enc.Base64.parse(this.check.dato)
    }, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
}

}
