import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {finalize} from 'rxjs/operators';
import { SpinnerService } from '../servicios/spinner.service';
import { environment } from 'src/environments/environment';
import { TokenService } from '../servicios/token.service';


@Injectable()
export class SpinnerInterceptor implements HttpInterceptor{
    idUsuario: string;
    constructor(
    private spnr:SpinnerService,
    private tokenService: TokenService) {
        this.idUsuario = this.tokenService.getUserName();
      }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //const dieta = `${environment.global}formularios/getCustomerPrompt/${this.idUsuario}?formFolio=4`;
        //console.log('mi req',req);
        //if(req.url && !req.url.includes('getCustomerPrompt')){
        this.spnr.show();
        //}
        return next.handle(req).pipe(
            finalize(()=> this.spnr.hide()));
    }

}

