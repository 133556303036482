import { CarritoPage } from 'src/app/pages/carrito/carrito.page';

export const environment = {
  production: false,

  //ONE SIGNAL
  appid: '780cb048-4776-490e-929d-7329745eab91',
  getOne: 'https://api.onesignal.com/apps/',
  notification: false,

  //ALPHA
  getNoticiasJoomla: 'api/v1/category/list/72/content', //BANNERS
  getNoticiasJoomla_page_alpha: 'api/v1/category/list/19/content', //NOTICIAS
  getNoticiasJoomla_page_alpha_avisos: 'api/v1/category/list/73/content', //AVISOS
  getNoticiasJoomla_page_alpha_horarios: 'api/v1/category/list/74/content', //HORARIOS
  direccionJoomla: 'https://clubalpha.com.mx/', //URL
  token: `mQB3GkINZMTNkY5jJkWMNNUOT24wMTO2j9ZG1WO`, //TOKEN

  //CIMERA
  getImageC: 'https://cimera.com.mx/wp-json/wp/v2/',
  getPosteoImageC: 'media?parent=3923',
  getPosteoHorarioC: 'media?parent=3955',
  getUrlImagenesC: 'wp-json/wp/v2/media/',
  getPostImagenC: 'archivos/3923/',
  getPostHorarioC: 'archivos/3955/',
  direccionWPC: 'https://cimera.com.mx/',

  //SPORTS
  getImage: 'https://sportsplaza.mx/wp-json/wp/v2/',
  getPosteoImage: 'media?parent=988808',
  getUrlImagenes: 'wp-json/wp/v2/media/',
  getPostImagen: 'archivos/988808/',
  direccionWP: 'https://sportsplaza.mx/',
  nPosteo: 'slider',
  nPost: 'horario',

  terminos: CarritoPage.terminos,

  //RAZÓN SOCIAL //alpha //sports //cimera //
  club: 'cimera',
  web: true,
  productoDomiciliacion: 2731,

  //ENDPOINT GLOBAL
  global: 'https://appalpha.ngrok.io/', // URL SERVIDOR PRODUCCION
  // global: 'http://192.168.20.57:8080/', //URL SERVIDOR PRUEBAS http://192.168.20.102:8080/
  // global: 'http://192.168.16.118:8080/', //URL SERVIDOR PRUEBAS http://192.168.20.102:8080/
  // global: 'http://192.168.20.102:8080/',
  facturacion: 'https://appalpha.ngrok.io/', //URL SERVIDOR PRODUCCION
  //facturacion: 'http://192.168.20.57:8090/',  //URL SERVIDOR PRUEBAS
  //facturacion: 'http://192.168.20.102:8080/',  //URL SERVIDOR PRUEBAS

  //Chatwoot
  tokenChatWootA3:'T2VkrSdvrrvmMGyMqhtdE6UW',
  tokenChatWootA2:'Fb2EQDbzgu6B4xiCuqNDXBnR',
  tokenChatWootCIM:'d3zqjy8JfQErP48Bku9TrVED',
  tokenChatWootSP:'U2MZkEiq8HrxRJvbiFtWJBXW',
};
