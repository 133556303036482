import { Cliente } from './../../models/cliente';
import { ClienteService } from './../../servicios/cliente.service';
import { ActivatedRoute} from '@angular/router';
import { TokenService } from './../../servicios/token.service';
import { AuthService } from './../../servicios/auth.service';
import { AlertController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-custom-profile2',
    templateUrl: './custom-profile2.component.html',
    styleUrls: ['./custom-profile2.component.scss'],
})

export class CustomProfile2Component implements OnInit {
    @Input() user: any;
    @Input() list: any;
    cliente: Cliente;

    constructor(public alertController: AlertController, public authService: AuthService,
        public tokenService: TokenService, private clienteService: ClienteService,
        private activatedRoute: ActivatedRoute, private router: Router
        ) {}

    misDatos(): void{
        this.router.navigate(['/misdatos/' + this.tokenService.getUserName()]);//+ this.TokenService.getUserName()])
    }
    
    pass(): void {
        this.router.navigate(['/pass/' ]);
    }

    esta(): void {
        this.router.navigate(['/estacionamiento/' ]);
    }

    ngOnInit() {
        this.cargarCliente();
      }

    cargarCliente(){
        const id = this.activatedRoute.snapshot.params.id; //este es para obtener el id de la url
        //console.log(id);
        //toma una variable en este caso toma la del idcliente que se encuentra en la url
        this.clienteService.obtCliente(id).subscribe(   //aqui no creo que sea necesario jalar el id
          (data)=>{
           console.log(data); //muestra los datos en consola
            this.cliente=data as unknown as Cliente;
            //localStorage.getItem(this.cliente.club.nombre);
          },
          err =>{
          }
        );
    }

    cargaralpha():boolean{  
        if (environment.club === 'alpha') {
        return true;
        }
    }

    cargacimera():boolean{  
        if (environment.club === 'cimera') {
        return true;
        }
    }

    cargarsports():boolean{
        if (environment.club === 'sports') {
        return true;
        }
    }
}
