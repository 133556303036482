import { environment } from './../../environments/environment';
import { JwtDto } from '../models/jwt-dto';
import { Observable } from 'rxjs';
import { LoginUsuario } from 'src/app/models/login-usuario';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient ) { }
  public login(loginUsuario: LoginUsuario): Observable<JwtDto>{
    return this.httpClient.post<JwtDto>(environment.global + 'auth/login', loginUsuario);
  }
}
