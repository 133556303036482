import { environment } from './../../environments/environment';
import { TokenService } from './token.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cliente } from './../models/cliente';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  static idusuario:any;
  static edad: number;
  static banda: string;
  static sexo: number;
  constructor(private httpClient: HttpClient, private tokenService: TokenService, private storage: Storage) { }

    //Este metodo sirve para mostrar un cliente, no necesita concatenar el id del cliente,
    //aunque no lo pida  otra opcion :`obtenerCliente/${id}`
    public obtCliente(id: number): Observable<Cliente>{

      const cliente = this.httpClient.get<Cliente>(environment.global +'alpha/obtenerCliente/' +
      this.tokenService.getUserName());
      cliente.subscribe((data)=>{
        const id = ClienteService.idusuario = data.idCliente;
        ClienteService.edad =moment().diff(moment(data.fechaNacimiento),'years');
        ClienteService.banda = data.idComplexBand;
        ClienteService.sexo=data.idSexo;
        this.storage.set('datosCliente',data);
        this.storage.set('idCliente',id);
      });
      return cliente; //'obtenerCliente' + this.TokenService.getUserName()
      //+ this.TokenService.getUserName
    }
    public async cargarCliente(): Promise<Cliente>{
      return await this.storage.get('idCliente');
    }
    public async cargarDatosCliente(): Promise<Cliente>{
      return await this.storage.get('datosCliente');
    }
}
