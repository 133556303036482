import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-custom-news',
  templateUrl: './custom-news.component.html',
  styleUrls: ['./custom-news.component.scss'],
})
export class CustomNewsComponent {
  @Input() list: Array<any>;
  @Output() onSearch = new EventEmitter();

  constructor() { }
  toggleLiked(card: any) {

    if (card.icon === 'heart') {
      card.icon = 'heart-outline';
    } else {
      card.icon = 'heart';
    }
  }
  search(event) {
    this.onSearch.emit(event);
}
}
