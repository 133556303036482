import { NavController } from '@ionic/angular';
import {Component, Input, ViewEncapsulation} from '@angular/core';
import {SideMenuOption} from './models/side-menu-option';
import { TokenService } from '../../servicios/token.service';

@Component({
    selector: 'custom-side-menu',
    templateUrl: './custom-side-menu.component.html',
    styleUrls: ['./custom-side-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CustomSideMenuComponent {
    optionHeight = 45;
    paddingLeft = 16;
    @Input() menuList: Array<SideMenuOption>;

    isLogged=false;
    nombreUsuario='';
    password='';

    constructor( private navCtrl:NavController, private tkS:TokenService) {
    }

    toggle(item) {
        item.expanded = !item.expanded;
    }

    vaciar() {
      this.nombreUsuario = '';
      this.password = '';
    }

    salir(){
      localStorage.setItem('ingresado','false');
      this.tkS.logOut();
      this.isLogged = false;
      this.vaciar();
      this.navCtrl.navigateRoot('/login');
    }
}
