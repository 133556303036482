import { ModalController, AlertController, ToastController } from '@ionic/angular';
import { MovimientosService } from './../../servicios/movimientos.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-formulario-facturacion',
  templateUrl: './formulario-facturacion.component.html',
  styleUrls: ['./formulario-facturacion.component.scss'],
})
export class FormularioFacturacionComponent implements OnInit {
  @Input() folio;
  @Input() idUsuario;
  @Input() datos;
  ionicForm: FormGroup;
  defaultDate = "1987-06-30";
  isSubmitted = false;
  formasDePago = [
    { clave: '01', dato: 'Efectivo' },
    //{clave:'02',dato: 'Cheque nominativo'},
    { clave: '03', dato: 'Transferencia electrónica de fondos' },
    { clave: '04', dato: 'Tarjeta de crédito' },
    // {clave:'05',dato: 'Monedero electrónico'},
    //{clave:'06',dato: 'Dinero electrónico'},
    //{clave:'08',dato: 'Vales de despensa'},
    //{clave:'12',dato: 'Dación en pago'},
    //{clave:'13',dato: 'Pago por subrogación'},
    //{clave:'14',dato: 'Pago por consignación'},
    //{clave:'15',dato: 'Condonación'},
    //{clave:'17',dato: 'Compensación'},
    //{clave:'23',dato: 'Novación'},
    //{clave:'24',dato: 'Confusión'},
    //{clave:'25',dato: 'Remisión de deuda'},
    //{clave:'26',dato: 'Prescripción o caducidad'},
    //{clave:'27',dato: 'A satisfacción del acreedor'},
    { clave: '28', dato: 'Tarjeta de débito' },
    //{clave:'29',dato: 'Tarjeta de servicios'},
    //{clave:'30',dato: 'Aplicación de anticipos'},
    { clave: '99', dato: 'Por definir' }];
  usoCFDI = [
    //{clave:'G01',dato: 'Adquisición de mercancías'},
    //{clave:'G02',dato: 'Devoluciones, descuentos o bonificaciones'},
    { clave: 'G03', dato: 'Gastos en general' },
    //{clave:'I01',dato: 'Construcciones'},
    //{clave:'I02',dato: 'Mobiliario y equipo de oficina por inversiones'},
    //{clave:'I03',dato: 'Equipo de transporte'},
    //{clave:'I04',dato: 'Equipo de computo y accesorios'},
    //{clave:'I05',dato: 'Dados, troqueles, moldes, matrices y herramental'},
    //{clave:'I06',dato: 'Comunicaciones telefónicas'},
    //{clave:'I07',dato: 'Comunicaciones satelitales'},
    //{clave:'I08',dato: 'Otra maquinaria y equipo'},
    //{clave:'D01',dato: 'Honorarios médicos, dentales y gastos hospitalarios'},
    //{clave:'D02',dato: 'Gastos médicos por incapacidad o discapacidad'},
    //{clave:'D03',dato: 'Gastos funerales'},
    //{clave:'D04',dato: 'Donativos'},
    //{clave:'D05',dato: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)'},
    //{clave:'D06',dato: 'Aportaciones voluntarias al SAR'},
    //{clave:'D07',dato: 'Primas por seguros de gastos médicos'},
    //{clave:'D08',dato: 'Gastos de transportación escolar obligatoria'},
    //{clave:'D09',dato: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones'},
    //{clave:'D10',dato: 'Pagos por servicios educativos (colegiaturas)'},
    { clave: 'S01', dato: 'Sin efectos fiscales' },
    //{clave:'CP01',dato: 'Pagos'},
    //{clave:'CN01',dato: 'Nómina'}
  ];
  usoCFDI2 = [{ clave: 'S01', dato: 'Sin efectos fiscales' }];
  metodosDePago = [
    { clave: 'PUE', dato: 'Pago en una sola exhibición' },
    { clave: 'PPD', dato: 'Pago en parcialidades o diferido' }
  ]
  regimenFiscales = [
    { clave: '601', dato: 'General de Ley Personas Morales' },
    { clave: '603', dato: 'Personas Morales con Fines no Lucrativos' },
    { clave: '605', dato: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
    { clave: '606', dato: 'Arrendamiento' },
    { clave: '607', dato: 'Régimen de Enajenación o Adquisición de Bienes' },
    { clave: '608', dato: 'Demás ingresos' },
    { clave: '610', dato: 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
    { clave: '611', dato: 'Ingresos por Dividendos (socios y accionistas)' },
    { clave: '612', dato: 'Personas Físicas con Actividades Empresariales y Profesionales' },
    { clave: '614', dato: 'Ingresos por intereses' },
    { clave: '615', dato: 'Régimen de los ingresos por obtención de premios' },
    { clave: '616', dato: 'Sin obligaciones fiscales' },
    { clave: '620', dato: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
    { clave: '621', dato: 'Incorporación Fiscal' },
    { clave: '622', dato: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
    { clave: '623', dato: 'Opcional para Grupos de Sociedades' },
    { clave: '624', dato: 'Coordinados' },
    { clave: '625', dato: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
    { clave: '626', dato: 'Régimen Simplificado de Confianza' }
  ]
  data: any;
  constructor(public formBuilder: FormBuilder,
    private movimientoService: MovimientosService,
    private modalCtrl: ModalController,
    public toastController: ToastController,
    private alertController: AlertController) {

  }

  get errorControl() {
    return this.ionicForm.controls;
  }
  ngOnInit() {
    //console.log('mi folio es ',this.folio);
    console.log('Mi data fue', this.datos);

    this.ionicForm = this.formBuilder.group({
      name: [this.datos?.nombre, [Validators.required, Validators.minLength(1)]],
      rfc: [this.datos?.rfc, [Validators.required, Validators.minLength(12), Validators.maxLength(13)]],
      codigoPostal: [this.datos?.codigoPostal, [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(5), Validators.maxLength(5)]],
      email: [this.datos?.email, [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      pago: ['01'],
      observaciones: [],
      uso: [this.datos?.usoCFDI ? this.datos?.usoCFDI : 'S01'],
      //  metodo: ['PUE'],
      regimenFiscal: [this.datos?.regimenFiscal ? this.datos?.regimenFiscal : '612']
    });

  }
  enviarDatos() {
    this.isSubmitted = true;
    if (!this.ionicForm.valid) {
      console.log('Please provide all the required values!');
      this.presentToast();
      return false;
    } else {
      this.data = this.ionicForm.value;
      this.data.tipoCFDI = 'I';
      this.data.recibo = this.folio;
      this.data.usuario = this.idUsuario;
      console.log(this.data);

      this.movimientoService.facturar(this.data).subscribe(
        (res: any) => {
          console.log('Mi respuesta fue', res);
          this.presentAlertFacturado(this.data.email);
          this.modalCtrl.dismiss();
        },
        (error) => {
          console.log('mi respuesta', error);
          this.presentErrorFacturado(error.error.Message);

        }
      );
    }
  }
  cerrar() {
    this.modalCtrl.dismiss();
  }
  async presentErrorFacturado(mensaje: string) {
    const alert = await this.alertController.create({
      header: 'Error',
      subHeader: 'No se puede facturar',
      message: `El mensaje de error fue: "${mensaje}"`,
      buttons: ['OK']
    });
    await alert.present();
  }
  async presentAlertFacturado(mensaje: string) {
    const alert = await this.alertController.create({
      header: 'Exito',
      subHeader: 'Factura realizada',
      message: `La factura se ha enviado al correo "${mensaje}"`,
      buttons: ['OK']
    });
    await alert.present();
  }
  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Los datos de facturación no son válidos.',
      duration: 1000
    });
    toast.present();
  }
  elegirUsos(): boolean {
    var regimenFiscal = Number(this.ionicForm.value.regimenFiscal);
    //console.log('Mi regimen es', regimenFiscal);
    if ((regimenFiscal === 605) || (regimenFiscal === 607) || (regimenFiscal === 608) || (regimenFiscal === 609) || (regimenFiscal === 610)
      || (regimenFiscal === 611) || (regimenFiscal === 614) || (regimenFiscal === 615) || (regimenFiscal === 616)) {
        this.ionicForm.controls['uso'].setValue('S01');
        return false;
    }
    else {
    //  console.log('Retornare false ',regimenFiscal);
      return true;
    }
  }
}
