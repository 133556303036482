import { Component, Input, OnInit, } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { CarritoAlphaService } from 'src/app/servicios/carrito-alpha.service';
import { CarritoService } from 'src/app/servicios/carrito.service';
import { ClienteService } from 'src/app/servicios/cliente.service';
import { environment } from 'src/environments/environment';
;

@Component({
  selector: 'app-custom-product-card',
  templateUrl: './custom-product-card.component.html',
  styleUrls: ['./custom-product-card.component.scss'],
})
export class CustomProductCardComponent implements OnInit {
  @Input() producto;

  constructor(private carritoService: CarritoService,private toastController: ToastController) { }

  ngOnInit() {
  }

   // ---------------------Botón agregar al carrito-------------------------
  async onClick(event: Event){
    event.stopPropagation();
    this.carritoService.addQuantity(this.producto);
    const mensaje = 'Agregado al carrito';
    await this.presentToast(mensaje);
  }

  // --------------------Carga según club-------------------------

  cargaralpha(): boolean{
    if (environment.club === 'alpha') {
      return true;
    }
  }

  cargarcimera(): boolean{
    if (environment.club === 'cimera') {
      return true;
    }
  }

  cargarsports(): boolean{
    if (environment.club === 'sports') {
      return true;
    }
  }
  async presentToast(msj: string) {
    const toast = await this.toastController.create({
      message: msj,
      duration: 1000,
      position: 'bottom'
    });
    toast.present();
  }

}
