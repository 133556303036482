import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { RespuestaOxxo } from 'src/app/models/pago-oxxo.model';

@Component({
  selector: 'app-oxxo-pay',
  templateUrl: './oxxo-pay.component.html',
  styleUrls: ['./oxxo-pay.component.scss'],
})
export class OxxoPayComponent implements OnInit {
  @Input() referencia: RespuestaOxxo;

  constructor(private modalController: ModalController) {
    console.log('mi referencia ',this.referencia);
   }

  ngOnInit() {}
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss();
  }
}
