import { TokenService } from './token.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { RespuestaOxxo } from '../models/pago-oxxo.model';

@Injectable({
  providedIn: 'root'
})
export class OxxoPayService {

  constructor(private httpClient: HttpClient, private tokenService: TokenService) { }
  public obtenerReferencia(monto: number): Observable<RespuestaOxxo>{
    const datos = { idcliente: this.tokenService.getUserName(),
      monto};
    const options = {
       headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        //'responseType': 'text/plain;charset=UTF-8'
      }
    };
    return this.httpClient.post<RespuestaOxxo>(environment.global+'pagos/conekta',
     JSON.stringify(datos), options);
  }
}
