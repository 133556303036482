// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-custom-side-right',
//   templateUrl: './custom-side-right.component.html',
//   styleUrls: ['./custom-side-right.component.scss'],
// })
// export class CustomSideRightComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {}

// }

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-custom-side-right',
    templateUrl: './custom-side-right.component.html',
    styleUrls: ['./custom-side-right.component.scss'],
})
export class CustomSideRightComponent {
    selectType = 'SOCIAL';
    chatList = [
        {name: 'Clubs Alpha', status: 'Hoy', content: 'Nuevas membresías disponibles', img: 'assets/images/avatar/1.jpg'},
        {name: 'Alpha 2', status: 'Hace 1 día', content: 'Aacabamos de abrir nuestro bar', img: 'assets/images/avatar/2.png'},
        {name: 'EUDEP', status: 'Hce un día', content: 'Consulta nuestras becas', img: 'assets/images/avatar/3.jpg'},
        {name: 'Training House', status: 'Hace 2 días', content: 'Nuevas promociones en la página', img: 'assets/images/avatar/4.jpg'},
        {name: 'Alphacuatic', status: 'Hace 3 días', content: 'Clases en lina de natación', img: 'assets/images/avatar/5.png'},
        {name: 'Titanes', status: 'Hace 1 semana', content: 'Triunfo de los titanes en el torneo', img: 'assets/images/avatar/6.jpg'}
    ];
    sList = [
        {
            name: 'email',
            icon: 'mail',
            content: ' Escribenos a contacto@clubalpha.com.mx.',
        },
        {
            name: 'Twitter',
            icon: 'logo-twitter',
            content: ' Mira nuestros últimos comentarios en Twitter.',
        },
        {
            name: 'Facebook',
            icon: 'logo-facebook',
            content: ' Enterate de los últimos eventos en Facebook.',
        },
        {
            name: 'Instagram',
            icon: 'logo-instagram',
            content: ' Puedes ver todas las fotos de nuestro equipo en Instagram.',
        },
        {
            name: 'YouTube',
            icon: 'logo-youtube',
            content: ' Para ver algunos tutoriales y videos sobre nosotros.',
        }
    ];

    constructor() {
    }
}
