import { Injectable } from '@angular/core';
import { Pases, ProductoAlpha } from '../models/productoAlpha';
import { HttpClient} from '@angular/common/http';
import { ClienteService } from './cliente.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarritoAlphaService {
  // private productos: Array<ProductoAlpha>;

  constructor(private http: HttpClient, private clienteService: ClienteService
  ) { }

  public getProductos(idOdoo): Observable<ProductoAlpha[]>{
    return this.http.get<ProductoAlpha[]>(environment.global + `odoo/productos/${idOdoo}`);
  }

  public getDescountPases(idCliente, idPase): Observable<Pases[]>{
    return this.http.get<Pases[]>(environment.global + `odoo/pases/${idCliente}/${idPase}`);
  }

}
