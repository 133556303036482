import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pedido } from '../models/pedido';

@Injectable({
  providedIn: 'root'
})
export class PedidoService {

  constructor(private http: HttpClient) { }

   public getPedido(id: number): Observable<Pedido>{
    return this.http.get<Pedido>(environment.global + `alpha/obtenerPedidoId/${id}`);
  }
}
