import {timer} from 'rxjs';
import {Injectable} from '@angular/core';
import {AlertProvider, LoadingProvider} from '../../shared/providers';
import {FormGroup} from '@angular/forms';
import { NavController } from '@ionic/angular';

@Injectable({providedIn: 'root'})
export class LoginService {
    constructor(private loadingProvider: LoadingProvider,
                private alertProvider: AlertProvider,
                private navCtrl: NavController) {
    }

    async onLogin(form: FormGroup) {
        // const username = form.controls.email.value;
        // const pwd = form.controls.pwd.value;
        const loader = await this.loadingProvider.create();
        await loader.present();
        timer(2000).subscribe(r => {
            loader.dismiss().then(() => {
                this.alertProvider.present('¡Inicio de sesión correcto!');
                console.log('Ingresado');
                localStorage.setItem('ingresado','true');
                this.navCtrl.navigateRoot('menu/home');
            });
        }, error => loader.dismiss().then(() => this.alertProvider.present(error)));
    }
}
