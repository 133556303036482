import { Component, OnInit } from '@angular/core';
import { ProductoAlpha, } from 'src/app/models/productoAlpha';
import { environment } from 'src/environments/environment';
import { CarritoService } from '../../servicios/carrito.service';
import { CarritoAlphaService } from '../../servicios/carrito-alpha.service';
import { Subscription } from 'rxjs';
import { ClienteService } from 'src/app/servicios/cliente.service';
import { AlertController, ToastController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { timer } from 'rxjs';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.page.html',
  styleUrls: ['./carrito.page.scss'],
})
export class CarritoPage implements OnInit {
  static idOdoo: number;
  static club: string;
  static terminos: boolean;

  todosLosProductos: Array<ProductoAlpha>;
  productos: ProductoAlpha[] = [];
  query!: string;
  isLoading = true;
  productsInCart = 0;
  cartSubscribe!: Subscription;
  tipoCliente: string;

  constructor(private carritoAlphaService: CarritoAlphaService ,private carritoService: CarritoService,
    private toastController: ToastController, private clienteService: ClienteService, private activatedRoute: ActivatedRoute,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
    if(environment.terminos === true){
      console.log('has aceoptado los terminos');
    }
    else{
      this.aceptarTerminos();
    }
    this.cargarCliente();
    timer(800).subscribe((r) => {
      if(!this.todosLosProductos){
        this.getProductos();
      }
    });

    this.cartSubscribe = this.carritoService.cart.subscribe({
      next: (cart) => {
        this.productsInCart = cart ?  cart?.itemsInCart: 0;
      },
      error: (e) =>{
        console.log(e);
      }
    });
  }

  // ----------Obtención de productos----------
  async getProductos(){
    console.log(CarritoPage.idOdoo);
    this.carritoAlphaService.getProductos(CarritoPage.idOdoo).subscribe({
      next: (data) => {
        this.todosLosProductos = data;
        this.productos= [...this.todosLosProductos];
        console.log('LISTA DE PRODUCTOS: ', this.productos);
        this.isLoading=!this.isLoading;
      },
      error: (error) => {
        console.error('Error al obtener los datos:', error);
        this.presentToast('Error al obtener los datos.');
        this.isLoading=!this.isLoading;
      }
    });

  }

  // ----------Manejo de búsqueda de productos----------
  onSearchChange(event: any){
    this.query = event.target.value.toLowerCase().normalize('NFD');
    this.querySearch();
  }

  querySearch(){
    this.productos = [];
    if (this.query.length > 0){
      this.searchItems();
    }else{
      this.productos = [...this.todosLosProductos];
    }
  }

  searchItems(){
    this.productos = this.todosLosProductos.filter((product) =>
      product.display_name_product_tmpl.toLowerCase().normalize('NFD').includes(this.query)
    );
  }

// --------------------Carga según club-------------------------

  cargaralpha(): boolean{
    if (environment.club === 'alpha') {
      return true;
    }
  }

  cargarcimera(): boolean{
    if (environment.club === 'cimera') {
      return true;
    }
  }

  cargarsports(): boolean{
    if (environment.club === 'sports') {
      return true;
    }
  }

  async presentToast(msj: string) {
    const toast = await this.toastController.create({
      message: msj,
      duration: 1000,
      position: 'bottom'
    });
    toast.present();
  }

  cargarCliente() {
    const id = this.activatedRoute.snapshot.params.id; //este es para obtener el id de la url
    this.clienteService.obtCliente(id).subscribe(
      (data) => {
        CarritoPage.idOdoo = data.idOdoo;
        CarritoPage.club = data.club.nombre;
        console.log('ID', CarritoPage.idOdoo);
        this.tipoCliente = data.tipoCliente.nombre;
      },
      (err) => { }
    );
  }

  alertTerminos(): Promise<boolean>{
    return new Promise((resolve) => {
      this.alertCtrl
        .create({
          header: 'PROGRAMA ALPHA PLUS',
          subHeader: 'SERVICIO: ACCEDE A NUESTROS 4 CLUBES DEPORTIVOS COMO MIEMBROS BENEFICIARIOS ACTIVOS DE LOS CLUBES ALPHA Y CIMERA.',
          message: 'Términos y condiciones: <br>'
          +'1.Programa ofrecido por FUNDACIÓN CLUB ALPHA DE PUEBLA, (en adelante CLUB ALPHA).<br>'
          +'2.El programa ALPHA PLUS consiste en dar acceso a los Miembros Beneficiarios activos de CLUB ALPHA a los Clubes Deportivos que integran a la FUNDACIÓN CLUB ALPHA DE PUEBLA. Mediante la compra de “visitas” que pueden adquirir a través de las aplicaciones móviles” CIMERA APP” y “CLUB ALPHA” descargables en IOS y “o ANDROID. Los usuarios visitantes podrán reservar clases e instalaciones mediante la app. <br>'
          +'3.Los accesos de visita son personales y serán utilizados por cualquier integrante de la membresía.<br>'
          +'4.La vigencia de la visita para cualquier ingreso a un Club Deportivo será de 30 días naturales.<br>'
          +'5.Los Miembros Beneficiarios activos, deberán estar al corriente de sus pagos de las membresías adquiridas, así como los servicios adicionales que contraten para aplicar el programa de Alpha Plus.<br>'
          +'6.Programa válido para todos los clubes deportivos de Fundación Club Alpha de Puebla, Club Alpha 2, Club Alpha 3, Sports Plaza y Cimera Gym Club.<br>'
          +'7.Programa válido para todos los Miembros Beneficiarios Activos y Dependientes que cuenten con una membresía activa individual, dual, Familiar, Grupal, Platinum, en cualquiera de sus modalidades, Biclub o Multiclub. Los accesos de visita son personales y utilizados por cualquier integrante de las membresías.<br>'
          +'8.Programa no válido para Usuarios Activos de Sports Plaza, ni usuarios con Horario Especial dentro de ellos clubes deportivos.  Usuarios externos o público en general.   No incluye los servicios de: Alphacuatic, Aquacimera, Health Studio Fisioterapia, Talleres Deportivos. Servicios que tienen costos adicionales en caso de adquirir dentro del club que se esté visitando.<br>'
          +'9.El programa no será aplicable para usuarios Wellhub y todos aquellos usuarios que no tengan una membresía activa de los clubs mencionados<br>'
          +'10.Para que el Miembro Beneficiario Activo o Dependiente pueda obtener su pase de visita, forzosamente, al ingresar al club solicitado, deberá ingresar por los torniquetes de entrada utilizando su registro facial; si por algún motivo imputable al Miembro Beneficiario Activo o Dependiente, el personal de Staff brinda el acceso sin dicho registro, la visita no será contabilizada para los efectos de la presente; sin embargo, si por fallas en el sistema de acceso al club, no se puede registrar la asistencia, personal de Staff intentará resolver en ese momento el inconveniente, de no ser posible esto, brindarán el acceso sin el registro en el sistema y reportan al área correspondiente su asistencia.<br>'
          +'11.Para efectos del presente programa, si el Miembro Beneficiario Activo o Dependiente asiste más de 2 (dos) veces en un día ya sea al mismo club o a otro distinto, se le contará como 1 (una) visita por acceso a cada club; asimismo no se acumularán visitas entre integrantes de una misma membresía, es decir que las visitas serán contabilizadas de manera individual por cada compra mediante “el carrito” de la app CIMERA APP” y “CLUB ALPHA”.<br>'
          +'12.En caso de que el Miembro Beneficiario Activo o Dependiente cancele su membresía durante la vigencia de la presente compra de “visita” o solicite el “permiso de Ausencia” de su membresía, previo al uso de su pase de “visita” se perderá, y no podrá ser utilizado por el dependiente y no podrá ser recuperado, reclamado ni reembolsado en efectivo ni en especie o cualquier otra modalidad de redención.<br>'
          +'13.En caso de que al Usuario Titular o Dependiente, le sea rescindido el contrato o suspendida la membresía temporalmente por faltas al mismo o al reglamento o que la membresía sea declarada inactiva por falta de pago, el pase de visita se perderá y no podrán ser recuperados, reclamados ni reembolsados en efectivo ni en especie.<br>'
          +'14.La promoción es intransferible e improrrogable y no podrá ser cambiada, canjeable o reembolsable por dinero en efectivo, ni por alguna otra modalidad de redención, ni en especie, ni por algún otro servicio ofrecido por Fundación Club Alpha de Puebla, asimismo no serán acumulable con otras promociones, ni descuentos.<br>'
          +'15.Si por causas de fuerza mayor no imputables a Fundación Club Alpha de Puebla, las autoridades ordenan el cierre de sus instalaciones durante la redención del pase de “visita”, éste se perderá y no serán objeto de reclamación ni recuperación alguna ni podrán ser prorrogable, canjeable ni reembolsable por dinero en efectivo, ni en especie o cualquier otra modalidad.<br>',
          buttons: [
            {
              text: 'Aceptar',
              role: 'confirm',
              handler: () => resolve(true),
            },
          ],
        })
        .then(alert => {
          // Now we just need to present the alert
          alert.present();
      });
    });
  }
  aceptarTerminos(){
    this.alertTerminos().then(ok => {
      if (ok){
        console.log('Haz aceptado los terminos y Condiciones');
        environment.terminos = true;
      }
    });
  }
}
