import { SettingsService } from './servicios/settings.service';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import OneSignal, { UserChangedState } from 'onesignal-cordova-plugin';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private settings: SettingsService) {
    if(environment.club === 'alpha'){
      settings.enableAlpha();
    }
    if(environment.club === 'sports'){
      settings.enableSportsPlaza();
    }
    if(environment.club === 'cimera'){
      settings.enableCimera();
    }

    // // Remove this method to stop OneSignal Debugging
    // OneSignal.Debug.setLogLevel(6);

    // // Replace YOUR_ONESIGNAL_APP_ID with your OneSignal App ID
    // OneSignal.initialize(environment.appid);

    // OneSignal.Notifications.addEventListener('click', async (e) => {
    //   const clickData = await e.notification;
    //   console.log('Notification Clicked : ' + clickData);
    // });

    // OneSignal.Notifications.requestPermission(true).then((success: boolean) => {
    //   console.log('Notification permission granted ' + success);
    //   environment.notification = success;
    // });

    // const listener = (event: UserChangedState) => {
    //   console.log('User changed: ' + (event));
    // };
  }
}
