import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../pages/login/login.service';

@Component({
  selector: 'app-custom-login',
  templateUrl: './custom-login.component.html',
  styleUrls: ['./custom-login.component.scss'],
})
export class CustomLoginComponent{
  lForm: FormGroup;
  logoList = ['logo-instagram', 'logo-facebook', 'logo-twitter'];
  animationClass = 'bounce-in-fwd';
  numberRegEx = '^[0-9]*$';

  constructor(private formBuilder: FormBuilder,
              private service: LoginService) {
      this.lForm = this.formBuilder.group({
          idUsuario: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
          pwd: ['', [Validators.required, Validators.minLength(6)]],
      });
  }

  async login() {
      await this.service.onLogin(this.lForm);
  }
}