import {Component, Input} from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';
import { TokenService } from 'src/app/servicios/token.service';
import { Pase } from 'src/app/models/pases';

import { environment } from 'src/environments/environment';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'custom-card3',
    templateUrl: './custom-card3.component.html',
    styleUrls: ['./custom-card3.component.scss'],
})
export class CustomCard3Component {
    @Input() list: Array<Pase>;

    constructor( private tokenService: TokenService) {
    }

    getQrCode(pase: Pase): string{
      let cadena: string;
      cadena ='PASE-';
      cadena+=pase.idProd+'-';
      cadena+=pase.f_compra+'-';
      cadena+=Md5.hashStr(pase.f_compra.toString()+this.tokenService.getUserName()+pase.idProd.toString())+'-';
      cadena+=pase.idVentaDetalle;
      cadena+='-'+this.tokenService.getUserName();
      return cadena;
    }

    getDate(fecha){
      return new Date(fecha);
    }

    getHasta(fecha): Date{
      const futureDate = new Date(fecha);
      futureDate.setDate(futureDate.getDate() + 45);
      return futureDate;
    }

    cargaralpha(): boolean{
      if (environment.club === 'alpha') {
        return true;
      }
    }

    cargacimera(): boolean{
      if (environment.club === 'cimera') {
        return true;
      }
    }

    cargarsports(): boolean{
      if (environment.club === 'sports') {
        return true;
      }
    }
}
