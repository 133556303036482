import { ModalRankingComponent } from './../modal-ranking/modal-ranking.component';
import {Component, Input, OnInit} from '@angular/core';
import { ModalController } from '@ionic/angular';
import {CardModel} from './card.model';
import { Cliente } from './../../models/cliente';
import { ClienteService } from './../../servicios/cliente.service';
import { ActivatedRoute} from '@angular/router';
import { RankingService } from '../modal-ranking/ranking.service';
import { RankingModel } from 'src/app/models/ranking.model';
//import { Health } from '@awesome-cordova-plugins/health/ngx';
import { Platform } from '@ionic/angular';

@Component({
    selector: 'custom-card1',
    templateUrl: './custom-card1.component.html',
    styleUrls: ['./custom-card1.component.scss'],
})
export class CustomCard1Component  implements OnInit{
    @Input() list: Array<CardModel>;
    @Input() user: any;
    //@Input() list2: any; 
   list3=[];
  // posicion: number ;
     list2: RankingModel[]=[];
    cliente: Cliente;
    pasos= []; 
    platformReady: Boolean = false;
  stepsArray = [];
  stepsAggregated = []
  hasData = false;
  pass;
 
    constructor(public modalController: ModalController,private clienteService: ClienteService,private activatedRoute: ActivatedRoute,private rank:RankingService, 
      //private health: Health,
      private platform: Platform) {
     
    }
   
  
    async checkPlatformReady() {
      const ready = !!await this.platform.ready();
      if (ready) {
        // this.health.isAvailable().then(bool => {
        //   console.log("available " + bool);
        //   if (bool) {
        //     this.platformReady = true;
        //     this.health.requestAuthorization([
        //       'distance', 'nutrition',  //read and write permissions
        //       {
        //         read: ['steps','height', 'weight'],       //read only permission
        //         write: ['height', 'weight']  //write only permission
        //       }
        //     ])
        //     .then(res => console.log("response " + res))
        //     .catch(e => console.log("error "+e));
        //   }
        // })
        // .catch(e => console.log("error "+e));
      }
    }

    ngOnInit() {
      this.cargarCliente();
  
      this.checkPlatformReady();
      //this.getAggregatedSteps();
    }
    
    segmentChanged(ev: any) {
      console.log('Segment changed', ev.detail.value);
    }
    
    ranking(id){
      this.rank.rankins(id);
    
    }

    async cargarRanking(){
      const modal = await this.modalController.create({
        component: ModalRankingComponent,
      });
      
      return await modal.present();
    }
    
    cargarCliente(){
      const id = this.activatedRoute.snapshot.params.id; //este es para obtener el id de la url
      //console.log(id);
      //toma una variable en este caso toma la del idcliente que se encuentra en la url
      this.clienteService.obtCliente(id).subscribe(   //aqui no creo que sea necesario jalar el id
        (data)=>{
         console.log(data); //muestra los datos en consola
          this.cliente=data as unknown as Cliente;
          //localStorage.getItem(this.cliente.club.nombre);
        },
        err =>{
        }
      );
  }
  
  getAggregatedSteps(){
    // this.health.queryAggregated({
    //   startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000), // ten days ago
    //   endDate: new Date(), // now
    //   dataType: 'steps',
    //   bucket: 'month',
    //   filtered: true
    // }).then(data=>{
    //   console.log(data);
    //   data.forEach(item => {
    //       this.stepsAggregated.push({start: item.startDate, end: item.endDate, count: item.value})
    //       this. pasos.push( item.value)
    //       this.pass += item.value;
    //   });
    // }).catch(e => {
    //   console.log("error "+ e);
    // })
  }

  inscripcion(id){
    //this.cargarCliente();
    this.getAggregatedSteps();
    const apellido= this.cliente.apellidoPaterno;
    const materno= this.cliente.apellidoMaterno;
    const nom= this.cliente.nombre;
    const ava= 'data:image/jpeg;base64,'+this.cliente.urlfoto.imagen;
    const pos = 9;
    // this.pass=0;
   
   //for(let i=0; i <this.pasos.length; i++)
   //{
     //this.pass=this.pass+parseInt(this.pasos[i])
  // }
  
  const puntos= parseInt(this.pasos[0]);
    
    this.rank.inscribir(id,{ posicion:pos,avatar:ava,nombre: nom,total:puntos })
    console.log(parseInt(this.pasos[0]))
    
 
    
  }
}

