import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

const TOKEN_KEY = 'AuthToken';
const USERNAME_KEY = 'AuthUsername';
const AUTHORITIES_KEY = 'AuthAuthorities';
const USERCLUB_KEY = 'Club';
const USERACTIVO_KEY = 'Activo';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private storage:Storage) { }

  public setToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public setUsername(userName: string): void {
    window.sessionStorage.removeItem(USERNAME_KEY);
    window.sessionStorage.setItem(USERNAME_KEY, userName);
  }

  public getUserName(): string{
    return window.sessionStorage.getItem(USERNAME_KEY);
  }

  public setClub(userClub: string): void {
    window.sessionStorage.removeItem(USERCLUB_KEY);
    window.sessionStorage.setItem(USERCLUB_KEY, userClub);
  }

  public getClub(): string{
    return window.sessionStorage.getItem(USERCLUB_KEY);
  }

  public setActivo(userActivo: string): void {
    window.sessionStorage.removeItem(USERACTIVO_KEY);
    window.sessionStorage.setItem(USERACTIVO_KEY, userActivo);
  }

  public getActivo(): string{
    return window.sessionStorage.getItem(USERACTIVO_KEY);
  }

  public setAuthorities(authorities: string[]): void{
    window.sessionStorage.removeItem(AUTHORITIES_KEY);
    window.sessionStorage.setItem(AUTHORITIES_KEY, JSON.stringify(authorities));
  }

  public getAuthorities(): string[]{
    const roles: string[] = [];
    if(sessionStorage.getItem(AUTHORITIES_KEY)){
      JSON.parse(sessionStorage.getItem(AUTHORITIES_KEY)).forEach(rol =>{
        roles.push(rol);
      });
    }
    return roles;
  }

  public logOut(): void{
    window.sessionStorage.clear();
    this.storage.clear();
  }
}
