import { Injectable } from '@angular/core';
import { TokenService } from './../servicios/token.service';
import {HttpEvent,HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FotoInterceptorService implements HttpInterceptor {

  constructor(private TokenService:TokenService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq =req;
    const token = this.TokenService.getToken();
    if(token!= null){
      authReq = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + token)}); 
    }
    return next.handle(authReq);
  }

}

export const interceptorProvider = [{provide: HTTP_INTERCEPTORS, useClass: FotoInterceptorService, multi: true } ];
