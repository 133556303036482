import { RespuestaOxxo } from './../models/pago-oxxo.model';
import { OxxoPayService } from './../servicios/oxxo-pay.service';
import { ModalController } from '@ionic/angular';
import { OxxoPayComponent } from './../components/oxxo-pay/oxxo-pay.component';
import { TokenService } from './../servicios/token.service';
import { PedidoService } from './../servicios/pedido.service';
import { Component, OnInit } from '@angular/core';
import { Pedido } from '../models/pedido';
import { timer } from 'rxjs';
import { Router} from '@angular/router';
import { ActivatedRoute} from '@angular/router';

import { environment } from 'src/environments/environment';
import { ClienteService } from '../servicios/cliente.service';
import { Cliente } from '../models/cliente';
import { OpenPayComponent } from '../components/open-pay/open-pay.component';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.page.html',
  styleUrls: ['./pagos.page.scss'],
})
export class PagosPage implements OnInit {

  pedido: Pedido;
  total: number;
  isLoading = true;
  cliente: Cliente;
  static idcliente: number;

  constructor(private tokenService: TokenService, private service: PedidoService, private modalController: ModalController,
    private activatedRoute: ActivatedRoute, private router: Router, private oxxoPay: OxxoPayService,
    private clienteService: ClienteService) {
      this.service.getPedido(Number(this.tokenService.getUserName())).subscribe(data => {
        console.log('Mi pedido ',data);
        this.pedido=data;
        PagosPage.idcliente = this.pedido.idCliente;
        if(this.pedido){
          this.total = this.pedido.pedidoDetalle.reduce((total,detalle)=>total+detalle.importe,0);}
        });
      }

  ngOnInit() {
    this.cargarCliente();
    timer(2000).subscribe(r =>{
      this.isLoading = false;
      this.validaTitular();
  });
  }

  async pagarOxxo(){
    const modal = await this.modalController.create({
      component: OxxoPayComponent,
      componentProps: {
        referencia: await this.getReferencia()
      }
    });
    return await modal.present();
  }
  async pagarOpenpay(){
    const modal = await this.modalController.create({
      component: OpenPayComponent,
      // componentProps: {
      //   referencia: await this.getReferencia()
      // }
    });
    return await modal.present();
  }

  async getReferencia(): Promise<RespuestaOxxo>{
    return await this.oxxoPay.obtenerReferencia(Number(this.total.toFixed(2))).toPromise();
  }

  pagarTarjeta(): void {
    // this.router.navigate(['menu/pago_online' ]);
    window.open('https://pagos.clubalpha.com.mx:8443/fiserv/pagar/' + this.cliente.idCliente);
  }

  cargaralpha(): boolean{
    if (environment.club === 'alpha') {
      return true;
    }
  }

  cargacimera(): boolean{
    if (environment.club === 'cimera') {
      return true;
    }
  }

  cargarsports(): boolean{
    if (environment.club === 'sports') {
      return true;
    }
  }
  validaTitular(): boolean{
    if(this.cliente.esTitular === true){
      console.log('Es titular');
      return true;
    }
  }
  cargarCliente(){
    const id = this.activatedRoute.snapshot.params.id; //este es para obtener el id de la url
    //console.log(id);
    //toma una variable en este caso toma la del idcliente que se encuentra en la url
    this.clienteService.obtCliente(id).subscribe(   //aqui no creo que sea necesario jalar el id
      (data)=>{
      //console.log(data); //muestra los datos en consola
        this.cliente=data as unknown as Cliente;
        //localStorage.getItem(this.cliente.club.nombre);
        console.log('DATOS MEM',data);
      },
      err =>{
      }
    );
  }
}
