import { ModalController } from '@ionic/angular';
import {  RankingService, } from './ranking.service';
import { Component, OnInit } from '@angular/core';
import { RankingModel } from 'src/app/models/ranking.model';


@Component({
  selector: 'app-modal-ranking',
  templateUrl: './modal-ranking.component.html',
  styleUrls: ['./modal-ranking.component.scss'],
})
export class ModalRankingComponent implements OnInit {
  ranking: RankingModel[]=[];
  posicion =0;
  constructor(private service: RankingService,
    private modalController: ModalController) {
    //this.ranking=service.getList();
    this.ranking= this.service.obtenerranking();
  }

  ngOnInit() {
   this.ranking= this.service.obtenerranking();
   console.log(this.ranking);
  }
obranking(id)
{
  this.service.rankins(id);
}
  
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss();
  }
}
